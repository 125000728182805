import LINKS from '@/constants/links';

import { getDeviceId, getGAClientId, getGASessionId } from './amplitude';

export const adjustAppsflyerKeysMapper = {
  utm_source: 'af_channel',
  utm_campaign: 'c',
};

export type TAppUtmUrl = {
  utm_source: string;
  utm_campaign: string;
};

export const utmAppsflyerKeys = ['utm_source', 'utm_campaign'] as const;

export const handleAppsflyerUtmUrl = (
  link = LINKS.APP_LINK_QR_CODE,
): string => {
  let cookieData = '';

  const queryData: { key: string; value: string }[] = JSON.parse(
    sessionStorage.getItem('queryData') || '[]',
  );

  const gaClientId = getGAClientId();
  const gaSessionId = getGASessionId();
  const amplitudeDeviceId = getDeviceId();

  const urlWithQueryParams = utmAppsflyerKeys.reduce<string>(
    (previousValue, currentValue) => {
      const queryItem = queryData.find(({ key }) => key === currentValue);

      if (queryItem) {
        const queryKey = adjustAppsflyerKeysMapper[currentValue];

        return `${previousValue}${previousValue ? '&' : '?'}${queryKey}=${
          queryItem.value
        }`;
      }
      return previousValue;
    },
    '',
  );

  if (gaClientId || gaSessionId || amplitudeDeviceId) {
    cookieData = `&af_sub1=${gaClientId}&af_sub2=${gaSessionId}&af_sub3=${amplitudeDeviceId}`;
  }

  return urlWithQueryParams ? link + urlWithQueryParams + cookieData : link;
};
