'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import QRCodeImage from 'react-qr-code';

import Icon from '@/components/v3/Icon';
import { ACTIONS, CATEGORIES } from '@/constants/analytics';
import LINKS from '@/constants/links';
import { GAsendEvent } from '@/utils/Analytics';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';

import ClientOnlyPortal from '../ClientOnlyPortal';

type TQRCode = {
  qrCodeLink?: string;
  isShowing: boolean;
  onToggle: () => void;
  title?: string;
  description?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const QRCode: React.FC<TQRCode> = ({
  qrCodeLink = LINKS.APP_LINK_QR_CODE,
  isShowing,
  onToggle,
  title = 'Baixe o App Stone e abra sua Conta PJ grátis',
  description = 'Com a câmera do seu celular aberta, escaneie o QR Code acima e baixe o app Stone. Abra sua conta grátis e em alguns minutos!',
}) => {
  const modalRef = useRef<HTMLHeadingElement | null>(null);
  const [utmUrl, setUtmUrl] = useState(qrCodeLink);

  const closeQRCodeModal = useCallback(
    (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      const contain = modalRef.current?.contains(event.target as Node);
      if (!contain) {
        onToggle();
        document.body.removeEventListener('click', () =>
          closeQRCodeModal(event),
        );
      }
    },
    [onToggle],
  );
  const updateUtmUrl = useCallback(
    () => setUtmUrl(handleAppsflyerUtmUrl(qrCodeLink)),
    [qrCodeLink],
  );

  useEffect(updateUtmUrl);

  return (
    <ClientOnlyPortal selector="#drawer">
      <div
        className={classNames(
          'fixed z-[10000] top-0 overflow-hidden h-screen bg-display-900 bg-opacity-25',
          {
            'w-0': !isShowing,
            'w-screen': isShowing,
          },
        )}
        onClickCapture={(event: MouseEvent<HTMLDivElement>) =>
          closeQRCodeModal(event)
        }
      >
        <div
          className={classNames(
            'flex relative flex-col justify-center items-center p-24 md:p-40 xs:ml-auto w-full max-w-[480px] h-full  bg-display-0 rounded-tl-2xl transition-all',
            {
              'transform-gpu translate-x-[100%]': !isShowing,
              'transform-gpu translate-x-0': isShowing,
            },
          )}
          ref={modalRef}
        >
          <button
            type="button"
            className="flex absolute top-24 md:top-40 right-24 md:right-40 justify-center items-center p-8 md:w-40 md:h-40 bg-display-100 rounded-full"
            onClick={onToggle}
            aria-label="close"
          >
            <Icon
              name="close"
              className="w-24 h-24 text-display-600 fill-current"
            />
          </button>
          <div className="hidden md:flex">
            {utmUrl && <QRCodeImage size={136} value={utmUrl} />}
          </div>
          <p className="hidden md:flex mt-24 font-bold text-center heading-3">
            {title}
          </p>
          <p className="hidden md:flex mt-16 text-center paragraph-18">
            {description}
          </p>
          <div className="flex md:hidden">
            <Image
              src="/v1/site-stone/common/green-pig"
              width={96}
              height={80}
              alt="Imagem de um porquinho verde"
            />
          </div>
          <p className="flex md:hidden mt-24 text-center heading-3">
            Baixe o aplicativo Stone e abra sua conta PJ gratuita
          </p>
          <a
            href={utmUrl}
            className="flex md:hidden mt-16 w-full xs:w-max btn btn-primary btn-large"
            onClick={() => {
              GAsendEvent(
                CATEGORIES.SITE_STONE,
                ACTIONS.CLICK_CTA,
                `Drawer Mobile - Abra sua conta`,
              );
            }}
          >
            Abra sua conta
          </a>
        </div>
      </div>
    </ClientOnlyPortal>
  );
};

export default QRCode;
